import { FunctionComponent, useEffect, useState } from "react";
import styles from "./LoadingScreen.module.css";

const LoadingScreen: FunctionComponent = () => {
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);

  useEffect(() => {
    // Trigger loading screen animation on component mount
    setShowLoadingScreen(true);
  }, []);

  return (
    <div className={`${styles.comingSoon} ${showLoadingScreen ? styles.fadeIn : ""}`}>
      <img
        className={styles.steamuserimagesA1Icon}
        alt=""
        src="/steamuserimagesa-1@2x.png"
      />
      <div className={styles.rustTextFrame}>
        <img className={styles.rbLogo13Icon} alt="" src="/rb-logo1-3@2x.png" />
        <div className={`${styles.rust} ${showLoadingScreen ? styles.fadeIn : ""}`}>RUST</div>
        <div className={`${styles.bonanza} ${showLoadingScreen ? styles.fadeIn : ""}`}>BONANZA</div>
      </div>
      <div className={`${styles.comingSoon1} ${showLoadingScreen ? styles.fadeIn : ""}`}>COMING SOON</div>
    </div>
  );
};

export default LoadingScreen;






